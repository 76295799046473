////
/// @group Variables colors
/// @type color
////

$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);

$cream: rgb(239, 241, 242);
$red: rgb(174, 21, 25);
$yellow: rgb(251, 190, 0);

$brand-01: #FF6666;
$brand-02: #FF6666;
$brand-03: rgb(247, 152, 28);

$ui-00: rgb(242, 242, 242);
$ui-01: rgb(234, 239, 240);
$ui-02: rgb(218, 226, 227);
$ui-03: rgb(172, 191, 193);
$ui-04: rgb(151, 175, 178);
$ui-05: rgb(151, 151, 151);

$text-01: rgb(32, 33, 33);
$text-02: rgb(127, 143, 164);
$text-03: rgb(53, 64, 82);
$text-04: rgb(99, 115, 129);

$complementary-01: rgb(232, 182, 21);
$complementary-02: rgb(236, 208, 120);

$main-color: $brand-01;
$main-color-02: $brand-02;
$secondary-color: $brand-03;

$border: $ui-03;
$shadow: mix($white, $black, 75%);

$rss-color: rgb(232, 136, 69);
$twitter-color: rgb(0, 172, 237);
$facebook-color: rgb(43, 65, 112);
$instagram-color: rgb(193, 53, 132);
$linkedin-color: rgb(0, 19, 181);
$youtube-color: rgb(255, 0, 0);

$gray: rgb(102, 102, 102);
$blue-input: rgb(43, 127, 209);
$red-error: rgb(226,76,77);
$red-input: rgb(226,76,76);
$yellow-link: rgb(253,208,67);
$gray-header: rgb(204,204,204);
$bg-aside: rgb(237, 240, 243);
$dark: rgb(51, 51, 51);
$bg-active-li: rgb(219, 227, 235);
$bg-section: rgb(247, 247, 247);
$th: rgb(153, 153, 153);
$bg-success-message: rgb(106, 238, 154);

////
/// Mixins.
/// @link http://thesassway.com/intermediate/leveraging-sass-mixins-for-cleaner-code
/// @group mixins
////

/// Absolute gradient
@mixin absolute-gradient($direction: to top, $color-from: transparent, $color-to: transparent, $pseudo: after) {
  position: relative;

  &:#{$pseudo} {
    background: linear-gradient(#{$direction}, #{$color-from}, #{$color-to});
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

/// Absolute link
@mixin absolute-link {
  background: none;
  display: block;
  font-size: 0;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:hover {
    background: none; } }

// Apply "loading screen" to hide patterns while its is loading.
@mixin apply-loading-screen {
  //position: relative

  //&:after
  //animation: component .5s infinite alternate
  //content: ''
  //display: block
  //height: 100%
  //left: 0
  //opacity: 1
  //position: absolute
  //top: 0
  //transition: .2s
  //visibility: visible
 }  //width: 100%

/// Block title.
@mixin page-title {
  color: $black;
  display: block;
  font-family: $barlow;
  font-size: calc-rem(28px);
  font-weight: bold;
  line-height: 1.1;
  //margin-bottom: 1rem
  @include breakpoint($large) {
    font-size: calc-rem(38px); } }

/// Border.
@mixin border($width: 1px, $style: solid, $color: $border, $position: style) {
  border-#{$position}: $style;
  border-color: $color;
  border-width: $width; }

/// Date field
@mixin date-field {
  display: block;
  padding-bottom: 1rem;
  position: relative;

  &::after {
    background-color: tint($main-color, 60%);
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    max-width: 50px;
    position: absolute;
    width: 100%; }

  time {
    color: rgb(155, 155, 155);
    font-size: calc-rem(14px); } }

/// Buttons.
@mixin button($button-style) {
  border: 1px solid transparent;
  border-radius: 0;
  font-size: calc-rem(14px);
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: calc-rem(10px) calc-rem(24px);
  transition: .2s;
  @include breakpoint($large) {
    font-size: calc-rem(15px); }

  &:hover {
    transition: .2s; }

  @if $button-style == 'primary' {
    background-color: $main-color;
    border-color: $main-color;
    color: $white;

    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $white; } }

  @else if $button-style == 'secondary' {
    background-color: transparent;
    border-color: $main-color;
    color: $main-color;
    text-transform: uppercase;

    &:hover {
      background-color: $main-color;
      border-color: $main-color;
      color: $white; } } }

/// Card.
@mixin card {
  background-color: $white;
  border-radius: 5px;
  padding: 1rem 2rem; }

@mixin clean-list {
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  padding: 0; }

/// Mixins for clear.
@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: table; } }

/// Container.
@mixin container($max-width: $container-width) {
  margin: 0 auto;
  max-width: $max-width; }

/// Flex gallery
/// @param {number} $items - Numbers of items for row to show.
/// Usage guide:
/// - First add next properties to wrapper: display: flex, flex-flow: row wrap
/// - Then, apply the mixin to each item.

@mixin flex-gallery($items) {
  width: (100% - ($column-gap * ($items - 1))) / $items;
  @if $items == 1 {
    margin-bottom: 1rem; }
  @else {
    margin-bottom: $column-gap; }

  &:nth-child(#{$items}n) {
    margin-right: 0; }

  &:not(:nth-child(#{$items}n)) {
    margin-right: $column-gap; } }

/// Full content
@mixin full-content($max-width: $container-width) {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 ($container-padding + .45rem);
  position: relative;
  right: 50%;
  width: 100vw;

  > * {
    @include container($max-width); } }

/// Gradient.
@mixin gradient($direction: to top, $color-from: transparent, $color-to: transparent) {
  background: linear-gradient(#{$direction}, #{$color-from}, #{$color-to}); }

/// Identify an element on the screen
/// @access public
/// @param {Color} $color - Border color
@mixin here($color: $red) {
  border: 2px solid $color; }

/// Hide accessible.
@mixin hide-accessible {
  color: transparent;
  font-size: 0;
  line-height: 0; }

/// Icomoon.
@mixin icomoon($image, $font-size, $color, $bg-size: $font-size, $bg-color: transparent, $br-radius: 0, $pseudo: before) {
  @include hide-accessible;
  background-color: $bg-color;
  border-radius: $br-radius;
  display: block;
  height: calc-rem($bg-size);
  position: relative;
  width: calc-rem($bg-size);

  &:#{$pseudo} {
    color: $color;
    content: $image;
    font-family: $icomoon-font;
    font-size: calc-rem($font-size);
    font-weight: normal;
    left: 50%;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); } }

/// Icomoon text.
@mixin icomoon-text($image, $font-size, $color, $position: left, $align: center, $bg-size: $font-size, $bg-color: transparent, $br-radius: 0, $pseudo: before) {
  padding-#{$position}: calc-rem($bg-size / 1px * 1.25px);
  position: relative;

  &:#{$pseudo} {
    align-items: center;
    background-color: $bg-color;
    border-radius: $br-radius;
    color: $color;
    content: $image;
    display: flex;
    font-family: $icomoon-font;
    font-size: calc-rem($font-size);
    font-weight: normal;
    height: calc-rem($bg-size);
    justify-content: center;
    line-height: 1;
    position: absolute;
    width: calc-rem($bg-size);

    @if $position == 'top' {
      top: 0;
      @if $align == 'start' {
        left: 0; }
      @else if $align == 'center' {
        left: 50%;
        transform: translateX(-50%); }
      @else if $align == 'end' {
        right: 0; } }

    @else if $position == 'right' {
      right: 0;
      @if $align == 'start' {
        top: 0; }
      @else if $align == 'center' {
        top: 50%;
        transform: translateY(-50%); }
      @else if $align == 'end' {
        bottom: 0; } }

    @else if $position == 'bottom' {
      bottom: 0;
      @if $align == 'start' {
        left: 0; }
      @else if $align == 'center' {
        left: 50%;
        transform: translateX(-50%); }
      @else if $align == 'end' {
        right: 0; } }

    @else if $position == 'left' {
      left: 0;
      @if $align == 'start' {
        top: 0; }
      @else if $align == 'center' {
        top: 50%;
        transform: translateY(-50%); }
      @else if $align == 'end' {
        bottom: 0; } } } }

// Image cover.
@mixin image-cover {
  overflow: hidden;
  position: relative;

  img {
    height: auto;
    left: 50%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; } }

/// Style list - arrows.
@mixin list-style($list-style) {
  margin: .5rem 0;
  padding: 0 !important;

  @if $list-style == 'arrows' {
    li {
      @include icomoon-text(seticon(chevron-right), 12px, $text-03, right, start, 44px);
      border-bottom: 1px solid $border;
      margin: calc-rem(4px) 0;
      padding: calc-rem(12px) 0; } }

  @else if $list-style == 'squares' {
    li {
      @include icomoon-text(seticon(squares), 15px, $main-color, left, start, 24px);
      margin: calc-rem(20px) 0; } } }



/// Opening line
@mixin opening-line {
  color: #637381;
  font-size: calc-rem(16px);
  line-height: 1.31; }

/// Remove "loading screen" from patterns.
@mixin remove-loading-screen {
  //&:after
  //opacity: 0
  //transition: .2s .5s
 }  //visibility: hidden

@mixin sidebar-menu {
  background-color: $ui-02;
  border-color: $main-color;
  border-style: solid none none;
  border-width: calc-rem(45px);
  position: relative;
  @include breakpoint($large) {
    border-style: none none none solid; }

  > .menu {
    padding: 1rem 1.5rem; }

  .menu {
    display: block;
    width: 100%;

    a {
      color: $black;
      display: block;
      font-family: $source;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.25;
      padding: .5rem 0;
      text-transform: none;

      &:hover,
      &.is-active {
        color: $main-color; }

      &.is-active {
        + .menu {
          display: none; } } }

    li {
      &.menu-item--expanded,
      &.menu-item--collapsed {
        > a {
          @include icomoon-text(seticon(chevron-right), 12px, $black, right, center);

          &:hover {
            &:before {
              color: $main-color; } } } }

      &.menu-item--active-trail {

        > a:not(.is-active) {
          color: tint($text-03, 40%);
          display: block;
          font-weight: 600;
          margin-bottom: .5rem;
          padding:  0 0 .75rem;
          pointer-events: none;
          position: relative;
          text-transform: uppercase;

          &:after {
            background-color: tint($text-03, 40%);
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            max-width: calc-rem(50px);
            position: absolute;
            width: 100%; }

          &:before {
            content: none; } } } }

    //li
    //display: block

    //&:not(:last-child)
    //  border-bottom: 1px solid tint($text-03, 60%)

    //&:not(.menu-item--active-trail)
    //  display: none

    //&.menu-item--expanded
    //  > a
    //    &:not(.is-active)
    //      @include icomoon-text(seticon(chevron-right), 12px, $black, right, center)

    //    &:hover
    //      &:before
    //        color: $main-color

    //&.menu-item--active-trail
    //  border-bottom: 0

    //  > a
    //    &:not(.is-active)
    //      @include icomoon(seticon(arrow-left), 16px, $white, 45px)
    //      left: 0
    //      padding: 0
    //      position: absolute
    //      top: 0
    //      transform: translate(0, -100%)
    //      transition: .2s
    //      @include breakpoint($large)
    //        transform: translate(-100%, 0)

    //      &:hover
    //        @include breakpoint($large)
    //          transform: translate(-105%, 0)
    //          transition: .2s

    //      &:active
    //        @include breakpoint($large)
    //          transform: translate(-110%, 0)
    //          transition: .2s

    //    &.is-active
    //      color: tint($text-03, 40%)
    //      display: block
    //      font-weight: 600
    //      margin-bottom: .5rem
    //      padding:  0 0 .75rem
    //      pointer-events: none
    //      position: relative
    //      text-transform: uppercase

    //      &:after
    //        background-color: tint($text-03, 40%)
    //        bottom: 0
    //        content: ''
    //        display: block
    //        height: 2px
    //        max-width: calc-rem(50px)
    //        position: absolute
    //        width: 100%

    //      & + .menu

    //        > li
 } }    //          display: block

/// Paragraph with line on left
@mixin line-left {
  position: relative;
  padding-left: 110px;

  &::after {
    background-color: $brand-01;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 16px;
    width: 80px; }


  > div {
    &:first-child {
      //color: $black
      //font-size: 20px
      //font-weight: 600
      //margin: 0 0 18px
 }      //text-transform: uppercase

    &:nth-child(2) {
      //color: #253746
      //max-width: 800px

      p {
        margin-top: 0;
        padding: 0; } } } }

@mixin line-bottom {
  position: relative;
  padding-bottom: 20px;

  &::after {
    background-color: $brand-01;
    bottom: 0;
    content: '';
    height: 2px;
    left: 5px;
    position: absolute;
    width: 80px; } }


// Icomoon icons
$icons: (
  air-plane: ( base: '\e905', ),
  arrow-left: ( base: '\e90b', light: '\e906'),
  arrow-right: ( base: '\e90c', light: '\e907'),
  burger: ( base: '\e90a', ),
  bus: ( base: '\e904', ),
  caret-right: ( base: '\e90d', ),
  close: ( base: '\e912', light: '\e909'),
  chevron-up: ( base: '\e90e', ),
  chevron-right: ( base: '\e90f', ),
  chevron-down: ( base: '\e910', ),
  chevron-left: ( base: '\e911', ),
  csd: ( base: '\e913', ),
  dots: ( base: '\e914', ),
  electronic-headquarter: ( base: '\e91b', ),
  external: (base: '\e920',),
  facebook: ( base: '\e915', ),
  info: ( base: '\e902', ),
  instagram: ( base: '\e916', ),
  plus: ( base: '\e917', ),
  quote-end: ( base: '\e919', ),
  quote-start: ( base: '\e918', ),
  rss: ( base: '\e908', ),
  search: ( base: '\e91a', ),
  squares: ( base: '\e91c', ),
  train: ( base: '\e903', ),
  twitter: ( base: '\e91d', ),
  file: (base: '\e91e'),
  linkedin: (base: '\e900'),
  youtube: (base: '\e901'),
  dowload: (base: '\e91f'),
  dowload-link: (base: '\e921')
  );

// Social Network
$social-network: (
  rss: (seticon(rss), $rss-color),
  twitter: (seticon(twitter), $twitter-color),
  facebook: (seticon(facebook), $facebook-color),
  instagram: (seticon(instagram), $instagram-color),
  linkedin: (seticon(linkedin), $linkedin-color),
  youtube: (seticon(youtube), $youtube-color)
);

// Travel icons
$travels: (
  air-plane: seticon(air-plane),
  bus: seticon(bus),
  train: seticon(train),
  info: seticon(info)
);

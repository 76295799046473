////
/// @group Variables breakpoints
////

/// > 360px.
$small: 360px;

/// > 480px.
$x-small: 480px;

/// > 768px.
$medium: 768px;

/// > 1170px.
$large: 1170px;

/// > 1024px.
$medium-large: 1024px;

/// > 1280px.
$large: 1280px;

/// > 1367px.
$x-large: 1367px;

/// > 1440px.
$xx-large: 1440px;

/// > 1920px.
$monster: 1920px;

// From 0 to breakpoint.

/// Below $x-small (479px).
$below-x-small: $x-small - 1px;

/// Below $medium (767px).
$bellow-medium: $medium - 1px;

/// Below $large (1169px).
$bellow-large: $large - 1px;

/// Below $large (1279px).
$bellow-large: $large - 1px;

/// Below $xx-large (1439px).
$below-xx-large: $xx-large - 1px;

/// Below $monster (1919px).
$bellow-monster: $monster - 1px;

/// Internal
$fonts-path: '../fonts/' !default;

@font-face {
  font-family: "Nexa Bold";
  src: url($fonts-path + "NexaBold.otf") format("otf");
  font-weight: bold;
  font-style: bold; }

@font-face {
  font-family: "Nexa Light";
  src: url($fonts-path + "NexaLight.otf") format("otf");
  font-weight: light;
  font-style: light; }

@font-face {
  font-family: "Raleway Bold";
  src: url($fonts-path + "Raleway-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: bold; }

@font-face {
  font-family: "Lora";
  src: url($fonts-path + "Lora-Regular.ttf") format("ttf");
  font-weight: regular;
  font-style: regular; }


////
/// @group Variables fonts
////

/// Typography
$source: 'Source Sans Pro', sans-serif;
$aae: 'aae';
$icomoon-font: $aae;

/// @ignore Measurement.
$rem-base: 16px;

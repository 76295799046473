@charset 'utf-8';

*,
*::before,
*::after {
  box-sizing: border-box; }

// External vendors
@import '../../node_modules/sass-burger/burger';
@import '../../node_modules/normalize-libsass/normalize.scss';

// Fonts
@import 'utils/fonts';

// Resources (Dev libraries & utils)
@import 'resources';

// Base
@import "base/aux/_custom.sass";

// Layout Regions {Blocks, Forms, Nodes, Regions, Pages & Views}



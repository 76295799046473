.nav-header-color {
    background-color: #152935;
    padding: 0px;
    font-weight: bold;
    .principal {
        padding: 1%;
        width: 100%; }
    .links {
        li:first-child {
            margin-top: 5rem !important; }
        li:last-child {
            margin-bottom: 5rem !important; }
        li {
            margin: 2rem auto auto 0 !important;
            box-sizing: border-box;
            border: 1px solid $white;
            width: 330px;
            height: 65px;
            a {
                height: inherit;
                line-height: 48px;
                text-align: center; } } }
    .language-selector {
        text-transform: uppercase;
        li {
            a {
                font-size: 14px !important; }
            span {
                margin: 1% 0 1% 0 !important; } } }
    @include breakpoint($large) {
        .links {
            width: 700px;
            li:first-child {
                margin-top: 0px !important; }
            li:last-child {
                margin-bottom: 0px !important;
                margin-top: 0px !important;
                margin-right: 1rem !important; }
            li {
                margin-left: 3rem !important;
                margin-top: 0px !important;
                box-sizing: unset;
                border: none;
                width: auto;
                height: auto; } } } }

.flex-container {
    display: flex;
    flex-wrap: wrap;
    color: $white !important;
    padding: 3%;

    .icons, .links, .language-selector {
        display: flex;
        align-items: center; }

    .icons, .language-selector {
        flex: 1 0 50%; }

    .links {
        order: 0;
        flex-direction: column;
        flex: 0 1 100%; }

    .icons {
        order: 1;
        justify-content: flex-start; }

    .language-selector {
        order: 2;
        justify-content: flex-end; }

    .language-divider {
        margin: 0 0.25rem 0 0.25rem; }

    @include breakpoint($large) {
        display: flex;
        flex-wrap: unset;
        color: $white !important;
        padding: unset;
        .icons, .links, .language-selector {
            display: flex;
            align-items: center; }
        .icons, .language-selector {
            flex: unset; }
        .links {
            order: 0;
            flex-direction: row;
            flex: unset; }
        .icons {
            order: 0;
            justify-content: unset; }
        .language-selector {
            order: 0;
            justify-content: auto; }
        .language-divider {
            margin: auto; } } }

.navbar {
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05); }

.main-section-color {
	background-color: #F3F3F3; }

.second-section-color, body {
    font-family: 'Muli', sans-serif;
    background-color: #F7F7F7; }

.third-section-color {
    background-color: #F3F3F3; }

.fourth-section-color {
    background-color: #27CDE9; }

.footer-section-color {
    background-color: $black;
    color: white; }

.header-color {
    letter-spacing: 2.5px;
    a, span {
        color: #152935 !important;
        line-height: 32px;

        &.selected {
            color: $main-color-02 !important; } }
    .fab {
        font-size: 32px; }
    @include breakpoint($large) {
        a, span {
            line-height: 32px;

            &.selected {
                color: $main-color-02 !important; } }
        .fab {
            font-size: 32px; } } }

.highlight-text {
    color: $main-color-02;
    margin: 0px; }

.main-title {
    color: $black;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 40px;
    text-align: center;
    @include breakpoint($large) {
        font-size: 4.5rem;
        text-align: unset; } }

.why-title {
    color: $black;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 40px;
    text-align: center;
    @include breakpoint($large) {
        font-size: 3.5rem;
        text-align: unset; } }

.main-subtitle {
    color: $black;
    font-size: 18px; }

.jumbotron {
    margin-bottom: 0px; }

.video-button {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2.32px;
    background-color: $main-color-02;
    border-color: $main-color-02;
    font-weight: bold;
    margin-bottom: 5%;
    width: 100%;
    border-radius: 27.5px;
    @include breakpoint($large) {
        width: auto; }
    &.gradient {
        background: linear-gradient(205.49deg, #FF9F9F 0%, #FF6666 100%);
        border: 0px; }
    &:hover {
        background-color: $main-color-02;
        border-color: $main-color-02; }
    &:active {
        background-color: #ECECEC !important;
        color: $main-color-02 !important;
        border-color: $main-color-02 !important; }
    &.white {
        background-color: inherit;
        color: $main-color-02;
        &:hover {
            background-color: inherit; }
        &:active {
            background-color: $main-color-02 !important;
            border-color: $main-color-02 !important;
            color: #ECECEC !important; } }
    &.join {
        color: $white; } }
.why, .profits {
    .jumbotron-heading {}
    .text-muted {
        color: $white !important;
        padding: 0rem 0rem 1rem 0rem !important;
        @include breakpoint($large) {
            padding: 0rem 3rem 1rem 3rem !important; } }
    .row {
        color: $black;
        font-weight: bold;
        & .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 2rem;
            div {
                width: 100px; }
            img {
                height: 67px; }
            h2 {
                padding: 0rem 1.35rem 1rem 1.35rem !important;
                text-align: left;
                font-size: 14px; }
            @include breakpoint($large) {
                display: block;
                div {
                    width: auto; }
                img {
                    height: auto; }
                h2 {
                    padding: 0rem 3rem 1rem 3rem !important;
                    text-align: unset; } } }
        & .why-img {
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $white;
            font-size: 13px; }
        & h2 {
            font-size: 24px; }
        & p {
            font-weight: normal;
            color: #333333; } } }

.profits {
    & h2 {
        font-weight: bold; } }

.news {
    font-weight: bold;
    h1 {
        padding-bottom: 1rem !important; }
    & .row {
        min-height: 230px;
        & .news-card {
            border-bottom: 1px solid $main-color-02;
            padding-bottom: 1rem;
            & .link {
                font-size: 14px;
                letter-spacing: 2px;
                color: #152935;
                text-transform: uppercase;
                &:hover {
                    color: $main-color-02; }
                & .title {
                    color: $black;
                    &:hover {
                        color: $main-color-02; } } }
            & .fas {
                color: $main-color-02; } } }
    & .highlight-text {
        text-transform: uppercase;
        letter-spacing: 2px; }
    & .date {
        letter-spacing: 2.5px;
        font-size: 14px; }
    & .title {
        font-weight: bold;
        font-size: 24px; }
    & .body {
        font-weight: normal;
        font-size: 18px;
        color: #333333;
        word-break: break-word; } }

.about-us {
    color: $white;
    .highlight-text {
        color: $black; }
    .title {
        @include breakpoint($large) {
            font-size: 48px;
            font-size: 32px;
            font-weight: lighter; } }
    .subtitle {
        font-weight: bold; }
    .body {
        @include breakpoint($large) {
            font-size: 21px;
            font-size: 18px;
            padding: 1rem !important; } }
    .separator {
        display: flex;
        align-items: center;
        text-align: center;
        h4 {
            font-size: 18px; } }

    .separator::before, .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid $white; }

    .separator::before {
        margin-right: 1em; }

    .separator::after {
        margin-left: 1em; }

    .video-button {
        color: #119ECE;
        background-color: $white;
        border-color: $white; } }

.contact {
    .form-group {
        border: 1px solid $white;
        background-color: $white;
        text-transform: uppercase;
        label {
            padding: .375rem .75rem;
            font-size: 12px;
            font-weight: bold; }
        input, textarea {
            font-size: 18px;
            color: #CCCCCC; }
        .form-control {
            border: unset; }
        .form-control:focus {
            border: unset;
            box-shadow: unset; } }
    .form-group {
        &:focus-within label {
            color: $main-color-02; }
        &:focus-within {
            border: 1px solid #FF6666;
            box-shadow: 0 0 10px 0 #FF6666; } }
    .title {
        font-weight: 300;
        font-size: 48px; }
    .body {
        font-size: 22px;
        a:hover {
            text-decoration: none; } } }

.footer-text {
    font-size: 14px; }

.footer {
    .body {
        font-size: 14px; }
    .logo {
        height: 60px !important;
        margin: 1rem;
        @include breakpoint($large) {
            height: auto;
            margin: unset !important; } }
    .footer-text {
        text-align: center;
        @include breakpoint($large) {
            text-align: none; } }
    .logos {
        flex-direction: column;
        height: auto;
        @include breakpoint($large) {
            text-align: none;
            flex-direction: row;
            height: 67px;
            img {
                height: inherit; } } } }

.footer-hr {
    border: 1px solid #333333; }

.footer-2 {
    color: #CCCCCC;
    .links {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        flex-direction: column !important;
        align-items: center;
        @include breakpoint($large) {
            flex-direction: row !important;
            align-items: center; }
        a {
            color: #CCCCCC; }
        a:hover {
            text-decoration: none; } }
    .row {
        display: flex;
        flex-direction: column-reverse;
        .copy {
            justify-content: center !important; }
        @include breakpoint($large) {
            flex-direction: row; } } }

nav {
    a {
        &.navbar-brand {
            width: 78%;
            @include breakpoint($large) {
                width: 100%; }
            img {
                width: auto; } } } }

.news-card-img, .contact-img {
    display: none;
    @include breakpoint($large) {
        display: block !important; } }

.pagination {
    .page-item {
        .page-link {
            color: #152935;
            &:hover {
                color: $main-color-02; } }
        &.active {
            .page-link {
                background-color: #152935;
                border-color: #152935;
                color: $white;
                &:hover {
                    color: $main-color-02; } } } } }
